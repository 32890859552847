import '@hotwired/turbo-rails'
import 'arrive'
import {Collapse, Tab} from 'bootstrap'

import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
const application = Application.start()
const controllers = import.meta.glob('~/**/*_controller.js', { eager: true })
registerControllers(application, controllers)

// video player
document.arrive('.video-placeholder', { existing: true }, function(el) {
  el.addEventListener('click', function(event){
    el.classList.add('fadeOut')
    el.parentNode.querySelector('.video-play-symbol')?.classList.add('fadeOut')
    el.outerHTML = '<iframe src="' + el.dataset.video + '" width="560" height="315" allowfullscreen="1"></iframe>'
  })
})


// homepage animations
document.arrive('.wobbly-hero-tracker', { existing: true }, function(el) {
  const callback = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) { el.parentNode.classList.add('expanded') }
      else { el.parentNode.classList.remove('expanded') }
    })
  }
  new IntersectionObserver(callback, {}).observe(el)
})


// handle main menu clicks
document.arrive('nav#main-menu a', { existing: true }, function(el) {
  el.addEventListener('click', function(evt) {
    if (el.pathname === window.location.pathname && el.dataset.turbo != 'false') {
      evt.preventDefault()
      if (el.hash) {
        document.querySelectorAll('.wobbly-hero').forEach((e) => {e.classList.remove('expanded')})
        setTimeout(function(){document.querySelector(el.hash)?.scrollIntoView(true)}, 310)
        history.replaceState({}, '', el.hash)
      }
    }
    Collapse.getInstance(document.querySelector('#main-menu')).hide()
  })
})


// open direct tab or scroll to anchor when location.hash exists on load
document.addEventListener('turbo:load', function(event) {
  if (document.location.hash) {
    let tab_link = document.querySelector(`.nav a[href="${document.location.hash}"]`)
    if (tab_link) {
      new Tab(tab_link).show()
      scrollTo(0,0)
    } else {
      document.querySelector(document.location.hash)?.scrollIntoView(true)
    }
  }
})


// set hash when tab is selected
document.arrive('.nav a', { existing: true }, function(el) {
  el.addEventListener('shown.bs.tab', function(evt) {
    history.replaceState({}, '', evt.target.hash)
  })
})


// plausible.io + Turbo
document.addEventListener("turbo:load", function() {
  try { plausible('pageview') }
  catch(err) { console.log('Plausible: not available.') }
})


// make all external links open in new tab
document.arrive('.richtext', { existing: true }, function(el) {
  const selector = "a[href^='http']:not([href^='http://www.altopartners.de']):not([href^='http://altopartners.de']):not([href^='https://www.altopartners.de']):not([href^='https://altopartners.de'])"
  el.querySelectorAll(selector).forEach(function (e) {e.setAttribute("target", "_blank") })
})


// CSS Styles
import '~/stylesheets/website.scss'
